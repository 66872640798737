<template>
  <div class="sowings-home">
    <div class="df-flex-l df-flex-col-t df-flex-col-m">
      <div class="df-col-8 df-col-12-t df-col-12-m home__content">
        <sowing-view-header :sprayings="sprayings" />
        <div class="df-flex-l df-flex-col home__sprayings">
          <section class="df-flex-m df-flex-col sprayings__filters">
            <h2>{{ $t('SowingView.home.next_sprayings') }}</h2>
            <div class="df-flex-m align-center">
              <p class="filter__item">{{ $t('SowingView.home.filter_by') }}:</p>
              <df-filter-select
                v-model="filteredSpraying"
                :disabled="loadingSprayings"
                :items="sprayingFilters"
                @click="selectItem"
              />
            </div>
          </section>
          <section>
            <div v-if="loadingSprayings" class="df-flex-l df-flex-col">
              <v-skeleton-loader
                v-for="(_, index) in sprayings.length || 5"
                :key="index"
                height="68px"
                type="image"
                width="auto"
              />
            </div>
            <without-item-card
              v-else-if="!sprayings.length"
              name="add-spraying"
              text="SowingView.home.without_spraying_text"
              title="SowingView.home.without_spraying_title"
            />
            <spraying-list v-else :sprayings="sprayings" />
          </section>
        </div>
      </div>
      <sowing-view-right-panel
        class="df-col-4 df-col-12-t df-col-12-m"
        :sowing-infos="sowingInfos"
      />
    </div>
  </div>
</template>

<script>
import DfFilterSelect from '@/lib/components/FilterSelect/DfFilterSelect.vue'
import i18n from '@/language'
import SowingViewRightPanel from '@/modules/sowingView/components/SowingViewRightPanel.vue'
import SowingViewHeader from '@/modules/sowingView/components/SowingViewHeader.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import SprayingList from '@/modules/sowingView/components/list/SprayingList.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { logEvent, events } from '@/services/analytics'
import { EventBus } from '@/utils/eventBus'

const INITIAL_SPRAYING = {
  name: i18n.t('SowingView.filters.all_sprayings'),
  isDefault: true,
  value: 'all',
}

export default {
  name: 'SowingViewHome',

  components: {
    DfFilterSelect,
    SowingViewRightPanel,
    SowingViewHeader,
    SprayingList,
    WithoutItemCard,
  },

  data() {
    return {
      cropName: '',
      fieldName: '',
      filteredSpraying: INITIAL_SPRAYING,
      loadingSprayings: false,
      sowingId: this.$route.query.sowing_id,
      sowingInfos: {},
      sprayings: [],
      varietyName: '',
    }
  },

  provide() {
    return {
      getCropName: this.getCropName,
      getFieldName: this.getFieldName,
      getSprayingsLength: this.getSprayingsLength,
      hasRegisteredSpraying: this.hasRegisteredSpraying,
      requestSowingInfos: this.requestSowingInfos,
      requestSowingSprayings: this.requestSowingSprayings,
      getVarietyName: this.getVarietyName,
    }
  },

  async created() {
    logEvent(events.sowingViewModule.SowingView.visited_page)
    this.requestSowingInfos()
    this.requestSowingSprayings()
  },

  computed: {
    sprayingFilters() {
      return [
        INITIAL_SPRAYING,
        {
          name: this.$t('SowingView.filters.late'),
          isDefault: false,
          value: 'late',
        },
        {
          name: this.$t('SowingView.filters.done'),
          isDefault: false,
          value: 'done',
        },
      ]
    },
  },

  methods: {
    getCropName() {
      return this.cropName
    },
    getFieldName() {
      return this.fieldName
    },
    getSprayingsLength() {
      return this.sprayings.length
    },
    getVarietyName() {
      return this.varietyName
    },
    hasRegisteredSpraying() {
      return this.sprayings.some((spraying) => spraying.applied_date)
    },
    async requestSowingInfos() {
      this.sowingInfos = {}
      try {
        const params = {
          sowing_id: this.sowingId,
        }
        const { data } = await sowingViewService.getSowingInfos(params)
        this.sowingInfos = data
        this.cropName = data.field.sowings[0].crop_name
        this.fieldName = data.field.name
        this.varietyName = data.field.sowings[0].variety_name
        EventBus.$emit('sowingInfos', {
          crop_name: this.cropName,
          variety_name: this.varietyName,
        })
      } catch (error) {
        console.error(error)
      }
    },
    async requestSowingSprayings() {
      this.loadingSprayings = true
      const params = {
        sowing_ids: [this.sowingId],
        spraying_status: this.filteredSpraying.value,
      }
      try {
        const { data } = await sowingViewService.getSowingSprayings(params)
        this.sprayings = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingSprayings = false
      }
    },
    selectItem() {
      logEvent(events.sowingViewModule.SowingView.filtered_by_spraying)
      this.requestSowingSprayings()
    },
  },
}
</script>

<style lang="scss" scoped>
.sowings-home {
  max-width: 1456px;
  padding-left: 16px;
  margin: 0px auto;
  height: 100%;

  @include d(t) {
    padding: 0px;
  }
  @include d(m) {
    padding: 0px;
  }
  .home__content {
    @include d(t) {
      padding: 0px 16px;
    }
    @include d(m) {
      padding: 0px 16px;
    }
    .home__sprayings {
      .sprayings__filters {
        h2 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
