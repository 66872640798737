const MAX_SPRAYINGS_ALLOWED = {
  disease: {
    soybean: 7,
    wheat: 5,
    corn: 5,
  },
  // CM: não sei qual o nome que backend irá retornar, então vou manter os dois
  // "disease" e "fungicide" são a mesma coisa
  fungicide: {
    soybean: 7,
    wheat: 5,
    corn: 5,
  },
  herbicide: {
    // TODO
  },
}

export { MAX_SPRAYINGS_ALLOWED }
